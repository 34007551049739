import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37735931"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "terms_container" }
const _hoisted_2 = { class: "modal_container terms_modal" }
const _hoisted_3 = { class: "terms_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.store.state.appCtx.theme}` + '-theme')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName)))
        ])
      ])
    ])
  ], 2))
}